import React from 'react';
import MainLayout from '../layouts/mainLayout';
import img_404 from '../assets/img_404.png';

export default () => {
  return (
    <>
      <MainLayout>
        <div className="mgn-top-100 text-center mgn-bot-100">
          <div className="container-fluid">
            <div className="def-w-max">
              <img src={img_404} className="mgn-bot-30 img-404" />
              <h2 className="text-center">
                {' '}
                Oops! The page you were looking for does not exist.
              </h2>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
};
